<template lang="pug">
basicPages(:title="$t('unsubscribe.h1')")
  p.mb-5(v-html="$t('unsubscribe.text1')")
  BaseInputText(
    :label="$t('unsubscribe.label_input:email')",
    error="",
    v-model.trim="email"
  )
</template>

<script>
import basicPages from "@/components/structure/views/basicPages";
export default {
  name: "Cancelar",
  components: {
    basicPages,
  },
  data() {
    return {
      email: "",
    };
  },
};
</script>

<style lang="scss">
</style>
